<template>
  <el-card v-loading="loading"
    data-testid="CompanySupport"
    class="main-content">
    <template v-if="!showCustomerContacts && !showMegaportContacts">
      <p class="text-align-center font-weight-400">
        {{ $t('company-support.click-chat-icon-msg') }}
      </p>
    </template>
    <el-form v-else
      ref="techSupportDetailsForm"
      label-position="right"
      label-width="280px"
      :model="techSupportDetailsFields"
      :rules="techSupportDetailsRules"
      :disabled="readOnly">
      <template v-if="showCustomerContacts">
        <h3 class="mb-2">
          {{ $t('company-support.customer-supp-heading') }}
        </h3>
        <el-form-item :label="$t('company-support.tech-supp-phone')"
          prop="techSupportPhone">
          <el-input v-model="techSupportDetailsFields.techSupportPhone"
            :placeholder="$t('company-support.tech-supp-phone-placeholder')"
            data-demo="+11 12341234"
            name="techSupportPhone" />
        </el-form-item>

        <el-form-item :label="$t('company-support.tech-supp-email')"
          prop="techSupportEmail">
          <el-input v-model="techSupportDetailsFields.techSupportEmail"
            :placeholder="$t('company-support.tech-supp-email-placeholder')"
            data-demo="demo@email.com"
            name="techSupportEmail" />
        </el-form-item>

        <el-form-item>
          <el-tag v-if="!isCompanyAdmin && !isManagedAccount"
            type="warning">
            {{ $t('company-support.admin-warning') }}
          </el-tag>
        </el-form-item>
      </template>

      <h3 v-if="showMegaportContacts"
        class="mb-2">
        {{ $t('company-support.megaport-supp-heading') }}
      </h3>
      <el-form-item v-if="megaportContactDetails.accountManager.active"
        :label="$t('company-support.account-manager')"
        prop="accountManager">
        <span>
          {{ megaportContactDetails.accountManager.name }}
          <a :href="`mailto:${megaportContactDetails.accountManager.email}`"
            type="primary"
            class="ml-1"
            target="_blank">
            {{ megaportContactDetails.accountManager.email }}
          </a>
        </span>
      </el-form-item>

      <el-form-item v-if="megaportContactDetails.customerSuccessManager.active"
        :label="$t('company-support.customer-success-manager')"
        prop="customerSuccessManager">
        <span>
          {{ megaportContactDetails.customerSuccessManager.name }}
          <a :href="`mailto:${megaportContactDetails.customerSuccessManager.email}`"
            type="primary"
            class="ml-1"
            target="_blank">
            {{ megaportContactDetails.customerSuccessManager.email }}
          </a>
        </span>
      </el-form-item>

      <el-form-item v-if="showCustomerContacts"
        class="d-flex justify-content-end">
        <el-button @click="resetForm">
          {{ $t('users.reset') }}
        </el-button>
        <el-button type="primary"
          @click="submitForm">
          {{ $t('general.save') }}
        </el-button>
      </el-form-item>
    </el-form>
  </el-card>
</template>

<script>
import Vue from 'vue'
import { mapMutations, mapGetters, mapActions } from 'vuex'
import { PHONE_NUMBER_REGEX } from '@/validators'

export default Vue.extend({

  data() {
    return {
      techSupportDetailsFields: {
        techSupportPhone: '',
        techSupportEmail: '',
      },
      techSupportDetailsRules: {
        techSupportPhone: [
          { required: true, message: this.$t('validations.required', { thing: this.$t('company-support.tech-supp-phone') }), trigger: 'blur' },
          { pattern: PHONE_NUMBER_REGEX, message: this.$t('validations.phone-format'), trigger: 'blur' },
        ],
        techSupportEmail: [
          { required: true, message: this.$t('validations.required', { thing: this.$t('company-support.tech-supp-email') }), trigger: 'blur' },
          { type: 'email', message: this.$t('validations.email-invalid'), trigger: 'blur' },
        ],
      },
      loading: false,
    }
  },

  computed: {
    ...mapGetters('Auth', ['hasAuth', 'isManagedAccount', 'isPartnerSupported', 'isMegaportSupported', 'isPartnerSupported']),
    ...mapGetters('Company', ['techSupportDetails', 'megaportContactDetails']),
    isCompanyAdmin() {
      return this.hasAuth('company_admin')
    },
    readOnly() {
      return !this.isCompanyAdmin || this.isPartnerSupported
    },
    showCustomerContacts() {
      return !this.isManagedAccount || !this.isMegaportSupported
    },
    showMegaportContacts() {
      return this.megaportContactDetails?.accountManager?.active || this.megaportContactDetails?.customerSuccessManager?.active
    },
  },

  watch: {
    techSupportDetails: {
      immediate: true,
      handler() {
        this.setFormFields()
      },
    },
  },

  methods: {
    ...mapMutations('Notifications', ['notifyBad', 'notifyGood']),
    ...mapActions('Company', ['updateTechSupportDetails']),
    setFormFields() {
      if (this.techSupportDetails) {
        const { techSupportPhone = '', techSupportEmail = '' } = this.techSupportDetails
        this.techSupportDetailsFields.techSupportPhone = techSupportPhone
        this.techSupportDetailsFields.techSupportEmail = techSupportEmail
      } else {
        this.techSupportDetailsFields.techSupportPhone = ''
        this.techSupportDetailsFields.techSupportEmail = ''
      }
    },
    async submitForm() {
      try {
        await this.$refs.techSupportDetailsForm.validate()
      } catch (error) {
        const props = {
          title: this.$t('validations.failed'),
          message: this.$t('validations.correct-issues'),
          type: 'error',
          duration: 3000,
        }
        this.$notify(props)
        return
      }
      this.loading = true
      try {
        await this.updateTechSupportDetails({ ...this.techSupportDetailsFields })
        this.setFormFields()
        this.notifyGood({
          title: this.$t('general.success-updating', { thing: this.$t('menu.technical-support-details') }),
        })
      } catch (error) {
        this.notifyBad({
          title: this.$t('general.error-updating', { thing: this.$t('menu.technical-support-details') }),
          message: error.data.message,
        })
      } finally {
        this.loading = false
      }
    },
    resetForm() {
      this.$refs.techSupportDetailsForm.clearValidate()
      this.setFormFields()
    },
  },
})
</script>

<style lang="scss" scoped>
.main-content {
  max-width: 900px;
  min-width: 400px;
  margin: 20px auto;
  padding: 20px;
  background-color: var(--color-white);
  border: 1px solid var(--border-color-base);
  border-radius: var(--border-radius-base);
}
</style>
